import moment from 'moment';
import _ from 'lodash';

export const isInSubscription = (subscriptions) => _.some(subscriptions, (subscription) => {
  const start = subscription.planStartDate;
  const end = subscription.planEndDate;
  return moment().isBetween(moment(start), moment(end));
});

export const highestCurrentSubscription = (subscriptions = []) => {
  const currentSubscriptions = subscriptions
    .filter((subscription) => moment().isBetween(subscription.planStartDate, subscription.planEndDate))
    .map((subscription) => subscription.planSize);

  return currentSubscriptions.includes('big')
    ? 'Professional'
    : currentSubscriptions.includes('medium')
      ? 'Premium'
      : currentSubscriptions.includes('small')
        ? 'Starter'
        : 'Free';
};

export const hasEnoughSubscription = (subscriptions, nTotalTickets) => {
  if (nTotalTickets <= 50) {
    return true;
  }
  if (subscriptions.length < 1) {
    return false;
  }
  const now = moment();
  for (const subscription of subscriptions) {
    if (now.isAfter(moment(subscription.planStartDate))
      && now.isBefore(moment(subscription.planEndDate))) {
      if ((subscription.planSize === 'small' && nTotalTickets <= 200)
        || (subscription.planSize === 'medium' && nTotalTickets <= 500)
        || (subscription.planSize === 'big')) {
        return true;
      }
    }
  }
  return false;
};

export const subscriptionDescription = {
  small: {
    name: 'Starter',
    description: '',
    events: 'Unlimited',
    guests: '200',
    devices: 'Unlimited',
    features: 'Full',
    pricing: {
      month: 28,
      year: 238
    }
  },
  medium: {
    name: 'Premium',
    description: '',
    events: 'Unlimited',
    guests: '500',
    devices: 'Unlimited',
    features: 'Full',
    pricing: {
      month: 48,
      year: 468
    }
  },
  big: {
    name: 'Professional',
    description: '',
    events: 'Unlimited',
    guests: 'Unlimited',
    devices: 'Unlimited',
    features: 'Full',
    pricing: {
      month: 108,
      year: 1088
    }
  }
};
