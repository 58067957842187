export const firstLogin = async (idToken) => {
  const apiHost = import.meta.env.VITE_API_HOST;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'jwt-id-token': idToken
    }
  };
  return fetch(`${apiHost}/v1/first-login`, requestOptions);
};
