import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import cover from '../../assets/images/cover.webp';
import {
  LoginFirebase
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import gtm from '../../lib/gtm';

const useStyles = makeStyles(() => ({
  image: {
    backgroundImage: `url(${cover})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
}));

const Login = () => {
  const classes = useStyles();

  useEffect(() => {
    gtm.push({ event: 'login' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Log in - Qeerio</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Grid
          sx={{
            height: '100vh'
          }}
          container
        >
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            lg={7}
            className={classes.image}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={5}
          >
            <Container
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Card>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 4
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      mb: 3
                    }}
                  >
                    <div>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                      >
                        Log in
                      </Typography>
                    </div>
                    <Logo
                      sx={{
                        height: 60,
                        width: 60
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1
                    }}
                  >
                    <LoginFirebase />
                  </Box>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
