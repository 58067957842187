import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { firstLogin } from '../helpers/api';
import firebase from '../lib/firebase';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      const { metadata: { creationTime } } = user;
      if (moment(creationTime).isBetween(moment().subtract(10, 'seconds'), moment().add(10, 'seconds'))) {
        const currentUser = await firebase.auth().currentUser;
        const idToken = await currentUser.getIdToken();
        await firstLogin(idToken);
      }
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            id: user.uid,
            avatar: user.photoURL,
            email: user.email,
            name: user.displayName
          }
        }
      });
    } else {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }), [dispatch]);

  const signInWithEmailAndPassword = (email,
    password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithCustomToken = (token) => firebase.auth().signInWithCustomToken(token);

  const signInWithApple = () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email,
    password) => firebase.auth().createUserWithEmailAndPassword(email, password);

  const sendPasswordResetEmail = (email) => firebase.auth().sendPasswordResetEmail(email);

  const confirmPasswordReset = (code, newPassword) => firebase.auth().confirmPasswordReset(code, newPassword);

  const logout = async () => {
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUserWithEmailAndPassword,
        signInWithApple,
        signInWithEmailAndPassword,
        signInWithGoogle,
        sendPasswordResetEmail,
        confirmPasswordReset,
        signInWithCustomToken,
        currentUser: firebase.auth().currentUser,
        firebase,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
