import {
  Box,
  Backdrop,
  CircularProgress,
  Typography
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1
  }
})(Backdrop);

const LimitedBackdropComponent = ({ text, open }) => {
  const classes = useStyles();
  return (
    <LimitedBackdrop
      className={classes.backdrop}
      open={open}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CircularProgress
          color="primary"
        />
        {!_.isNil(text) && (
          <Typography
            mt={2}
            color="textPrimary"
            variant="h6"
          >
            {text}
          </Typography>
        )}
      </Box>
    </LimitedBackdrop>
  );
};

LimitedBackdropComponent.propTypes = {
  text: PropTypes.string,
  open: PropTypes.bool.isRequired
};

export default LimitedBackdropComponent;
