import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useAuth from '../../hooks/useAuth';
import AppStoreBadge from '../../assets/images/apple_badge.png';
import GooglePlayBadge from '../../assets/images/google-play-badge.png';
import Logo from '../Logo';
import NavSection from '../NavSection';
import { highestCurrentSubscription } from '../../helpers/subscriptions';

const sectionEventDef = {
  title: 'Events',
  items: [
    {
      title: 'All events',
      path: '/application/event-list',
      clickPath: '/application/event-list',
      icon: <EventAvailableIcon fontSize="small" />,
      pathsActive: [
        '/application/event-list'
      ]
    }
  ]
};

const sectionEventWithCurrent = (evtId, eventName) => ({
  ...sectionEventDef,
  items: [
    ...sectionEventDef.items,
    {
      title: eventName,
      icon: <CheckIcon fontSize="small" />,
      path: '/application/guest-list',
      clickPath: '/application/guest-list',
      pathsActive: [
        '/application/guest-list/:eventId',
        '/application/guest/:eventId/:guestId/edit',
        '/application/guest/:eventId/new',
        '/application/import-guests/:eventId'
      ],
      children: [
        {
          title: 'Guest list',
          path: '/application/guest-list/:eventId',
          clickPath: `/application/guest-list/${evtId}`,
          pathsActive: [
            '/application/guest-list/:eventId'
          ]
        },
        {
          title: 'Add guest',
          path: '/application/guest/:eventId/new',
          clickPath: `/application/guest/${evtId}/new`,
          pathsActive: [
            '/application/guest/:eventId/new'
          ]
        },
        {
          title: 'Import guests',
          path: '/application/import-guests/:eventId',
          clickPath: `/application/import-guests/${evtId}`,
          pathsActive: [
            '/application/import-guests/:eventId'
          ]
        }
      ]
    }
  ]
});

const sectionGeneral = {
  title: 'General',
  items: [
    {
      title: 'Licences',
      path: '/application/subscriptions',
      clickPath: '/application/subscriptions',
      pathsActive: [
        '/application/subscriptions'
      ],
      icon: <ShoppingCartIcon fontSize="small" />
    },
    {
      title: 'Support',
      path: '/application/support',
      clickPath: '/application/support',
      pathsActive: [
        '/application/support'
      ],
      icon: <HelpOutlineIcon fontSize="small" />
    }
  ]
};

const useStyles = makeStyles(() => ({
  googlePlayBadge: {
    maxWidth: '50%'
  },
  appStoreBadge: {
    maxWidth: '48%',
    padding: '3%'
  }
}));

const showCurrentEventPaths = [
  '/application/guest-list/:eventId',
  '/application/import-guests/:eventId',
  '/application/guest/:eventId/new',
  '/application/guest/:eventId/:guestId/edit'
];

const DashboardSidebar = (props) => {
  const classes = useStyles();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, currentUser } = useAuth();
  const [subscriptions, setSubscriptions] = useState([]);

  const [sectionEvent, setSectionEvent] = useState(sectionEventDef);

  const fetchSubscriptions = async () => {
    const apiHost = import.meta.env.VITE_API_HOST;
    const idToken = await currentUser.getIdToken();
    const subscriptionReponse = await fetch(`${apiHost}/v1/subscriptions`, {
      headers: {
        'jwt-id-token': idToken
      }
    });

    const fetchedSubscriptions = await subscriptionReponse.json();
    setSubscriptions(fetchedSubscriptions);
  };

  const fetchEvents = async () => {
    const apiHost = import.meta.env.VITE_API_HOST;
    const idToken = await currentUser.getIdToken();
    const response = await fetch(`${apiHost}/v1/events`, {
      headers: {
        'jwt-id-token': idToken
      }
    });

    const fetchedEvents = await response.json();
    return fetchedEvents;
  };

  const createEventSection = async () => {
    let matchs = showCurrentEventPaths.map((p) => matchPath({
      path: p,
      exact: true,
      strict: false
    }, location.pathname));

    matchs = matchs.filter((p) => !_.isNil(p));

    const isInEvent = matchs.length > 0;

    if (isInEvent) {
      const evtId = _.get(matchs, ['0', 'params', 'eventId']);
      const fetchedEvents = await fetchEvents();
      const theEvent = _.find(fetchedEvents, (e) => e.uid === evtId);
      if (!_.isNil(theEvent)) {
        setSectionEvent(sectionEventWithCurrent(evtId, theEvent.name));
      } else {
        setSectionEvent(sectionEventDef);
      }
    } else {
      setSectionEvent(sectionEventDef);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
    createEventSection();
  }, [location]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {/* <Scrollbar options={{ suppressScrollX: true }}> */}
      <Box display={{ xs: 'block', lg: 'none' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%'
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'background.default',
                borderRadius: 1,
                display: 'flex',
                overflow: 'hidden',
                p: 2
              }}
            >
              <RouterLink
                style={{
                  textDecoration: 'none'
                }}
                to="/application/account"
              >
                {!_.isNil(user.avatar) && (
                  <Avatar
                    src={user.avatar}
                    sx={{
                      cursor: 'pointer',
                      height: 48,
                      width: 48
                    }}
                  />
                )}
                {_.isNil(user.avatar) && (
                  <Avatar
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                      height: 48,
                      width: 48
                    }}
                  >
                    {
                      !_.isNil(user.name) && !_.isEmpty(user.name)
                        ? user.name.charAt(0).toUpperCase()
                        : !_.isNil(user.email) && !_.isEmpty(user.email)
                          ? user.email.charAt(0).toUpperCase()
                          : 'Q'
                    }
                  </Avatar>
                )}
              </RouterLink>
              <Box sx={{
                ml: 2
              }}
              >
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {
                    !_.isNil(user.name) && !_.isEmpty(user.name)
                      ? user.name.length > 18 ? `${user.name.substring(0, 18)}...` : user.name
                      : !_.isNil(user.email) && !_.isEmpty(user.email)
                        ? user.email.length > 18 ? `${user.email.substring(0, 18)}...` : user.email
                        : ''
                  }
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  Your licence:
                  {' '}
                  <Link
                    color="primary"
                    component={RouterLink}
                    to="/application/account?tab=billing"
                  >
                    {highestCurrentSubscription(subscriptions)}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box p={2}>
            <NavSection
              key={sectionEvent.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...sectionEvent}
            />
            <NavSection
              key={sectionGeneral.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...sectionGeneral}
            />
            {/* {sections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            ))} */}
          </Box>
        </Box>
        <Box>
          <Divider />
          <Box
            my={1}
            px={2}
            display="flex"
          >
            <img
              style={{
                cursor: 'pointer'
              }}
              src={GooglePlayBadge}
              alt="The google play badge"
              className={classes.googlePlayBadge}
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.qeerio.guestlist')}
            />
            <img
              style={{
                cursor: 'pointer'
              }}
              src={AppStoreBadge}
              alt="The apple store badge"
              className={classes.appStoreBadge}
              onClick={() => window.open('https://apps.apple.com/us/app/qeerio-guestlist/id1489870521?platform=iphone')}
            />
          </Box>
          <Divider />
          <Box p={2}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box display={{ xs: 'block', lg: 'none' }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

function Copyright() {
  return (
    <Typography
      variant="body2"
      align="center"
    >
      {'Copyright © '}
      Qeerio
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
