import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import UserIcon from '../../icons/User';

import useAuth from '../../hooks/useAuth';

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/authentication/login');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        <Typography>Unable to logout</Typography>, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        }
      );
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {!_.isNil(user.avatar) && (
          <Avatar
            src={user.avatar}
            sx={{
              cursor: 'pointer',
              height: 32,
              width: 32
            }}
          />
        )}
        {_.isNil(user.avatar) && (
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              fontSize: '16 px',
              height: 32,
              width: 32
            }}
          >
            {
              !_.isNil(user.name) && !_.isEmpty(user.name)
                ? user.name.charAt(0).toUpperCase()
                : !_.isNil(user.email) && !_.isEmpty(user.email)
                  ? user.email.charAt(0).toUpperCase()
                  : 'Q'
            }
          </Avatar>
        )}
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        // getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          {!_.isNil(user.name) && (
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {user.name}
            </Typography>
          )}
          {!_.isNil(user.email) && (
            <Typography
              color={!_.isNil(user.name) ? 'textSecondary' : 'textPrimary'}
              variant="subtitle2"
            >
              {user.email}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/application/account?tab=general"
          >
            <ListItemIcon>
              <UserIcon
                fontSize="small"
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  General
                </Typography>
              )}
            />
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/application/account?tab=billing"
          >
            <ListItemIcon>
              <AttachMoneyIcon
                fontSize="small"
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Billing
                </Typography>
              )}
            />
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/application/account?tab=security"
          >
            <ListItemIcon>
              <LockIcon
                fontSize="small"
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Security
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
