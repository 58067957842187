import _ from 'lodash';
import PropTypes from 'prop-types';
import LogoPng from './logo.png';

const Logo = ({ height, width }) => (
  <img
    height={!_.isNil(height) ? height : '52'}
    width={!_.isNil(width) ? width : '52'}
    src={LogoPng}
    alt="Logo"
  />
);

Logo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default Logo;
