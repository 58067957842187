import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/application/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const CreateEvent = Loadable(lazy(() => import('./pages/dashboard/CreateEvents')));
const QrCodes = Loadable(lazy(() => import('./pages/dashboard/QrCodes')));
const GuestList = Loadable(lazy(() => import('./pages/dashboard/GuestList')));
const ImportGuests = Loadable(lazy(() => import('./pages/dashboard/ImportGuests')));
const CreateGuests = Loadable(lazy(() => import('./pages/dashboard/CreateGuests')));
const EventList = Loadable(lazy(() => import('./pages/dashboard/EventList')));
const Support = Loadable(lazy(() => import('./pages/dashboard/Support')));
const ManageUsers = Loadable(lazy(() => import('./pages/dashboard/ManageUsers')));
const Subscriptions = Loadable(lazy(() => import('./pages/dashboard/Subscriptions')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        )
      },
      {
        path: 'password-reset',
        element: (
          <GuestGuard>
            <PasswordReset />
          </GuestGuard>
        )
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: 'application',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'event-list',
        element: <EventList />
      },
      {
        path: 'subscriptions',
        element: <Subscriptions />
      },
      {
        path: 'support',
        element: <Support />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'manage-users/:eventId',
        element: <ManageUsers />
      },
      {
        path: 'event',
        children: [
          {
            path: 'new',
            element: <CreateEvent />
          },
          {
            path: ':eventId/edit',
            element: <CreateEvent />
          },
          {
            path: ':duplicateId/duplicate',
            element: <CreateEvent />
          }
        ]
      },
      {
        path: 'guest',
        children: [
          {
            path: ':eventId/new',
            element: <CreateGuests />
          },
          {
            path: ':eventId/:guestId/edit',
            element: <CreateGuests />
          }
        ]
      },
      {
        path: 'guest-list',
        children: [
          {
            path: ':eventId',
            element: <GuestList />
          }
        ]
      },
      {
        path: 'qr-codes',
        children: [
          {
            path: ':eventId',
            element: <QrCodes />
          }
        ]
      },
      {
        path: 'import-guests',
        children: [
          {
            path: ':eventId',
            element: <ImportGuests />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/authentication/login" />
    // children: [
    //   {
    //     path: '/',
    //     element: <Navigate to="/authentication/login" />
    //   }
    // {
    //   path: 'browse',
    //   element: <BrowseLayout />,
    //   children: [
    //     {
    //       path: '/',
    //       element: <Browse />
    //     },
    //     {
    //       path: '/buttons',
    //       element: <BrowseButtons />
    //     },
    //     {
    //       path: '/inputs',
    //       element: <BrowseInputs />
    //     },
    //     {
    //       path: '/charts',
    //       element: <BrowseCharts />
    //     },
    //     {
    //       path: '/colors',
    //       element: <BrowseColors />
    //     },
    //     {
    //       path: '/data-display/detail-lists',
    //       element: <BrowseDetailLists />
    //     },
    //     {
    //       path: '/data-display/quick-stats',
    //       element: <BrowseQuickStats />
    //     },
    //     {
    //       path: '/data-display/tables',
    //       element: <BrowseTables />
    //     },
    //     {
    //       path: '/forms',
    //       element: <BrowseForms />
    //     },
    //     {
    //       path: '/modals',
    //       element: <BrowseModals />
    //     },
    //     {
    //       path: '/lists/grouped-lists',
    //       element: <BrowseGroupedLists />
    //     },
    //     {
    //       path: '/lists/grid-lists',
    //       element: <BrowseGridLists />
    //     },
    //     {
    //       path: '/typography',
    //       element: <BrowseTypography />
    //     }
    //   ]
    // },
    // {
    //   path: 'checkout',
    //   element: <Checkout />
    // },
    // {
    //   path: 'pricing',
    //   element: <Pricing />
    // },
    // {
    //   path: '401',
    //   element: <AuthorizationRequired />
    // },
    // {
    //   path: '404',
    //   element: <NotFound />
    // },
    // {
    //   path: '500',
    //   element: <ServerError />
    // },
    // {
    //   path: '*',
    //   element: <NotFound />
    // }
    // ]
  }
];

export default routes;
