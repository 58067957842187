import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import qs from 'qs';
import { Formik } from 'formik';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Apple
} from 'mdi-material-ui';
import _ from 'lodash';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  TextField,
  Typography,
  Link
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import LimitedBackdrop from '../../Common/LimitedBackdrop';

const LoginFirebase = (props) => {
  const isMountedRef = useIsMountedRef();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { signInWithEmailAndPassword, signInWithGoogle, signInWithApple, signInWithCustomToken } = useAuth();

  useEffect(async () => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!_.isNil(token)) {
      setIsLoading(true);
      await signInWithCustomToken(token);
      setIsLoading(false);
    }
  });

  const handleGoogleClick = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAppleClick = async () => {
    try {
      await signInWithApple();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div {...props}>
      <LimitedBackdrop
        text="Signing you in..."
        open={isLoading}
      />
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            password: Yup
              .string()
              .max(255)
              .required('Password is required')
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signInWithEmailAndPassword(values.email, values.password);

            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box
        sx={{ mt: 3 }}
        display="flex"
        justifyContent="space-between"
      >
        <Link
          color="textSecondary"
          component={RouterLink}
          to="/authentication/register"
          variant="body2"
        >
          Create new account
        </Link>
        <Link
          color="textSecondary"
          component={RouterLink}
          sx={{ mt: 1 }}
          to="/authentication/password-recovery"
          variant="body2"
        >
          Forgot password
        </Link>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mb: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Divider orientation="horizontal" />
        </Box>
        <Typography
          color="textSecondary"
          sx={{ m: 2 }}
          variant="body1"
        >
          OR
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Divider orientation="horizontal" />
        </Box>
      </Box>
      <Button
        fullWidth
        onClick={handleGoogleClick}
        sx={{
          backgroundColor: 'common.white',
          color: 'common.black',
          '&:hover': {
            backgroundColor: 'common.white',
            color: 'common.black'
          }
        }}
        variant="contained"
      >
        <Box
          alt="Google"
          component="img"
          src="/static/icons/google.svg"
          sx={{ mr: 1 }}
        />
        <Box ml={2}>
          Google
        </Box>
      </Button>
      <Box mt={2}>
        <Button
          fullWidth
          onClick={handleAppleClick}
          sx={{
            backgroundColor: 'rgb(0, 0, 0)',
            color: 'common.black',
            '&:hover': {
              backgroundColor: 'rgb(0, 0, 0)',
              color: 'common.black'
            }
          }}
          variant="contained"
        >
          <Apple
            sx={{
              color: 'rgb(255, 255, 255)'
            }}
          />
          <Box
            ml={2}
            sx={{
              color: 'rgb(255, 255, 255)'
            }}
          >
            Apple
          </Box>
        </Button>
      </Box>
    </div>
  );
};

export default LoginFirebase;
